/**
 * Nearest store
 */

let nearestStoreToggle = $('.js-nearest-store-toggle');
let nearestStoreContent = $('.js-nearest-store-content');
let nearestStoreClose = $('.js-nearest-store-close');
let nearestStore = $('.js-nearest-store');
/** 
 * Open Nearest Store
 */

function toggleNearestStore() {
    nearestStoreToggle.toggleClass('is-active');
    nearestStoreContent.toggleClass('is-open');
    nearestStoreContent.toggleClass('is-close');
    if (nearestStoreToggle.hasClass('is-active')) {
        //console.log("warptabs");
        wrapTabs(nearestStore);
        nearestStoreToggle.attr("aria-expanded","true");
    } else {
        closeWrapTabs();
        nearestStoreToggle.attr("aria-expanded","false");
    }
}

/**
 * Close Nearest Store
 */

function closeNearestStore() {
    nearestStoreToggle.removeClass('is-active');
    nearestStoreContent.removeClass('is-open');
    nearestStoreContent.addClass('is-close');
    nearestStoreToggle.attr("aria-expanded","false");
}

$(function() {
    nearestStoreToggle.on({
        'mousedown': function(e) {
            if (e.button == 0) {
                e.preventDefault();
                toggleNearestStore()
            }
            
        },
        "keydown": function(e) {
            if (e.key == 'Enter' || e.key === ' ') {
                e.preventDefault();
                toggleNearestStore()
            }
        }
    });

    nearestStoreClose.on({
        'mousedown': function(e) {
            if (e.button == 0) {
                e.preventDefault();
                closeNearestStore();
                closeWrapTabs();
            }
        },
        "keydown": function(e) {
            if (e.key == 'Enter' || e.key === ' ') {
                e.preventDefault();
                closeNearestStore();
                closeWrapTabs();
            }
        }
    });

    jQuery(document).on("click", function(event) {
        var jQuerytarget = jQuery(event.target);
        if (jQuery(nearestStoreToggle).hasClass('is-active')) {
            if (!jQuerytarget.closest('.js-nearest-store').length &&
                jQuery('.js-nearest-store').is(":visible")) {
                closeNearestStore();
            }
        }
    });
    jQuery(document).on("keydown", function(e) {
        if (e.key == "Escape") {
            if (jQuery(nearestStoreToggle).hasClass('is-active')) {
                if (nearestStore.is(":visible")) {
                    closeNearestStore();
                    nearestStoreToggle[0].focus();
                }
            }
        }
    })
});