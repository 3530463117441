if ($(".js-pass-to-show-quote-button").length) {
    
    function handlerObserver(entries, observer) {

        jQuery(entries).each(function(index, entry) {
            if (jQuery(".js-pass-to-show-quote-button").length > 0) {
                if (!entry.isIntersecting) {
                    jQuery(".js-site-header-desktop-quote-wrapper").show();
                    jQuery(".js-site-header-mobile-quote-wrapper").show();
                    jQuery(".js-site-header-mobile-phone").hide();
                } else {
                    jQuery(".js-site-header-desktop-quote-wrapper").hide();
                    jQuery(".js-site-header-mobile-quote-wrapper").hide();
                    jQuery(".js-site-header-mobile-phone").show();
                }
            }
        });
        /*
        for (entry of entries) {
            //console.log(entry.isIntersecting);
            if (!entry.isIntersecting) {
                jQuery(".js-site-header-desktop-quote-wrapper").show();
                jQuery(".js-site-header-mobile-quote-wrapper").show();
                jQuery(".js-site-header-mobile-phone").hide();
            } else {
                jQuery(".js-site-header-desktop-quote-wrapper").hide();
                jQuery(".js-site-header-mobile-quote-wrapper").hide();
                jQuery(".js-site-header-mobile-phone").show();
            }
        }
        */
    }

    let observer = new IntersectionObserver(handlerObserver);
    observer.POLL_INTERVAL = 100;
    let elementobs = document.querySelector(".js-pass-to-show-quote-button");
    observer.observe(elementobs);

}