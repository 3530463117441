/**
 * Show Modals
 */
let component_modalList_previousElement = null;

let component_modalList = {
    items: [
        { id: "aditional-products", trigger: ".js-hero-home-more-button", target: ".js-modal-quote", close: ".js-modal-quote-close" },
        { id: "header-quote-desktop", trigger: ".js-site-header-desktop-quote", target: ".js-modal-quote", close: ".js-modal-quote-close" },
        { id: "header-quote-mobile", trigger: ".js-site-header-mobile-quote", target: ".js-modal-quote", close: ".js-modal-search-close" },
        { id: "search", trigger: ".js-site-header-desktop-search-control", target: ".js-modal-search", close: ".js-modal-search-close" },
        { id: "turbo", trigger: ".js-button-quote-turbo-trigger", target: ".js-modal-turbo", close: ".js-modal-turbo-close" }
    ]
};

function showModal(container) {
    jQuery(container).addClass('is-open');

    /**
     * Habilita solo los elementos del Modal
     */
    //console.log(jQuery(container));
    jQuery("body > *").each(function(index, value) {
        //console.log(value);
        if (value !== jQuery(container)[0]) {
            value.inert = true;
        }
    });

    //console.log(container);
    wrapTabs(container);
}

function addModalListeners() {
    /**
     * Asigna a cada modal disponible los eventos de 'show' y 'close'
     */
    jQuery(component_modalList.items).each(function(index, el) {
        jQuery(el.trigger).off("mousedown");
        jQuery(el.trigger).on('mousedown', function(e) {
            e.preventDefault();
            switch (e.button) {
                case 0:
                    $('.js-button-quote-turbo-trigger').trigger('click');
                    component_modalList_previousElement = jQuery(this);
                    showModal(el.target);
                    jQuery(el.trigger).attr("aria-expanded","true");
                    break;
                default:
                    break;
            }
        }).on("keydown", function(event) {
            if (event.key == "Enter") {
                event.preventDefault();
                $('.js-button-quote-turbo-trigger').trigger('click');
                component_modalList_previousElement = jQuery(this);
                showModal(el.target);
                jQuery(el.trigger).attr("aria-expanded","true");
            }
        });

        //console.log(el.trigger + " [class$=__close]");
        jQuery(el.close).off("keydown");
        jQuery(el.close).on("keydown", function(event) {
            if (event.key == "Enter") {
                event.preventDefault();
                checkModalClose(el.trigger);
                jQuery(el.trigger).attr("aria-expanded","false");
            }
        });
        //jQuery(el.close).on('click', function(e) {
        jQuery(el.close).off('mousedown');
        jQuery(el.close).on('mousedown', function(e) {
            e.preventDefault();
            switch (e.button) {
                case 0:
                    checkModalClose();
                    jQuery(component_modalList.items).each(function(index,element){
                        jQuery(element.trigger).attr('aria-expanded',false);
                    }); 
                    //jQuery(el.trigger).attr("aria-expanded","false");
                    break;

                default:
                    break;
            }
        });
    });
}

function checkModalClose(focusOnClose) {
    //console.log("-- components.modal-desktop.checkModalClose");
    jQuery(component_modalList.items).each(function(index, el) {
        if (jQuery(el.target).hasClass('is-open')) {
            jQuery("body > *").each(function(index, value) {
                if (value !== jQuery(el.target)[0]) {
                    value.inert = false;
                }
            });
            jQuery(el.target).removeClass('is-open');
            closeWrapTabs();
        }
    });
    if (focusOnClose == undefined) {
        console.log("focusonclose indefinido");
        if (component_modalList_previousElement != null) {
            console.log(component_modalList_previousElement);
            jQuery(component_modalList_previousElement)[0].blur();
            component_modalList_previousElement = null;
        }
    } else {
        console.log("seleecionanr focusonclose");
        setTimeout(function(){
            jQuery(focusOnClose).focus();
        }, 100);        
        console.log(focusOnClose);
        component_modalList_previousElement = null;
    }

}
jQuery(function() {
    if (component_modalList != null) {
        addModalListeners();
    }
    jQuery(document).on('keydown', function(e) {
        if (e.key == "Escape") {
            jQuery(component_modalList.items).each(function(index, el) {
                    //console.log(jQuery(el.trigger));
                    if (jQuery(el.target).hasClass("is-open")) {
                        checkModalClose(component_modalList_previousElement);
                        if(jQuery("button[aria-expanded=true]").length){
                            jQuery("button[aria-expanded=true]").each(function(index,element){
                                console.log("aria expanded");
                                jQuery(element).attr('aria-expanded',false);
                            })
                        }
                    }
                })
                /*
                if (component_modalList_previousElement != null) {
                    jQuery(component_modalList_previousElement)[0].focus();
                    component_modalList_previousElement = null;
                }
                */
        }
    });
});