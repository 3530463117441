/**
 * Teaser Bar
 */

let teaserBar = $('.js-teaser-bar');
let teaserBarClose = $('.js-teaser-bar-close');

$(function () {

    teaserBarClose.on('click', function () {
        teaserBar.fadeOut();
    });
});