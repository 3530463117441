/**
 * Skip Content
 */

let skipButton = $('.js-site-header-skip');
let targetSkip = $('#main');
let siteHeader = $('.c-site-header');

if (skipButton.length) {
    skipButton.on('click', function () {
        $('html, body').animate({
            scrollTop: targetSkip.offset().top - siteHeader.outerHeight()
        });
    });
}