var faqAsideList = jQuery('.c-faqs-aside-list__link');
var faqQuestionListTile = jQuery(".c-faqs-questions-list__title");
var faq_first_el = null;
var faq_last_el = null;
var stickyFaqs = {
    heightHeader: function() {
        var sticky = new Sticky('[data-sticky]', {});

        let headerHeight = $('.c-site-header').height() + 45;

        $(window).on('resize', function() {
            headerHeight = $('.c-site-header').height() + 45;
        });

        $('.c-faqs-aside-list__link').on({
            'click': function(event) {
                if (event.button == 0) {
                    event.preventDefault();
                    activateAsideFaq(jQuery(this));
                    /*
                                $('.c-faqs-aside-list__link').removeClass('is-active');
                                $(this).addClass('is-active');
                                
                                
                                $('.c-faqs-questions-list__title').removeClass('is-active');
                                target.find('.c-faqs-questions-list__title').addClass('is-active')
                    */
                    var target = $($(this).attr('href'));
                    $('html,body').animate({
                        scrollTop: target.offset().top - headerHeight
                    });
                }
            },
            "keydown": function(event) {
                if (event.key == 'Enter' || event.key == ' ') {
                    event.preventDefault();
                    activateAsideFaq(jQuery(this));
                    var target = $($(this).attr('href'));

                    $('html,body').animate({
                        scrollTop: target.offset().top - headerHeight
                    });
                }
            }
        });
    }
}

function activateAsideFaq(faqItem) {
    faqItem.removeClass('is-active');
    faqAsideList.each(function(index, item) {
        jQuery(item).removeClass('is-active');
    });
    faqItem.addClass('is-active');
    var target = jQuery(faqItem.attr('href'));
    faqQuestionListTile.each(function(index, item) {
        jQuery(item).removeClass('is-active');
    });
    target.find('.c-faqs-questions-list__title').addClass('is-active');
    jQuery(target)[0].focus();
    tabElementsQuestion(target);
}

function tabElementsQuestion(target) {
    var focusElements_str = "a,input,button";
    var focusElements = jQuery(target).find(focusElements_str);
    faq_first_el = focusElements.first();
    faq_last_el = focusElements.last();
    jQuery(focusElements).each(function(indice, item) {
        jQuery(item).off("keydown");
        jQuery(item).on("keydown", function(e) {
            if (e.key == "Tab") {
                var currentfocus = jQuery(":focus");
                if (e.shiftKey) {
                    if (currentfocus.is(faq_first_el)) {
                        e.preventDefault();
                        faq_first_el = null;
                        faq_last_el = null;
                        goToFaqMenuItem(jQuery(this), "prev");
                    }
                } else {
                    if (currentfocus.is(faq_last_el)) {
                        e.preventDefault();
                        faq_first_el = null;
                        faq_last_el = null;
                        goToFaqMenuItem(jQuery(this), "next");
                    }
                }
            }
        })
    });
}

function goToFaqMenuItem(currElement, newPos) {
    var currentpos = jQuery(".js-faqs-aside-link.is-active").closest('li').index();
    var tmp = 0;

    switch (newPos) {
        case 'prev':
            tmp = (currentpos - 1 >= 0) ? currentpos - 1 : 0;
            break;

        case 'next':
            tmp = (currentpos + 1 <= faqsAsideLink.length - 1) ? currentpos + 1 : faqsAsideLink.length - 1;
            break;
    }

    faqsAsideLink.removeClass('is-active');
    faqsAsideLink.eq(tmp)[0].focus();
}
$(function() {

    if ($('.c-faqs').length) {
        stickyFaqs.heightHeader();
    }
})