let wrapTabsFirstChild = null;
let wrapTabsLastChild = null;
let keyboardNavigation = false;

/**
 * Agregando clase para indicar navegacion por teclado
 */
jQuery(document).on('keydown', function(e) {
    if (e.key == 'Tab') {
        jQuery('body').addClass('is-keyboard-pressed');
        keyboardNavigation = true;
    };
});


jQuery(document).on('click mousedown', function(e) {
    var $target = jQuery(e.target);
    if ($target.is(':radio, :checkbox, :button, a[href^="#"]')) {
        if (keyboardNavigation == false) {
            jQuery('body').removeClass('is-keyboard-pressed');
            keyboardNavigation = false;
        }
    } else {
        jQuery('body').removeClass('is-keyboard-pressed');
        keyboardNavigation = false;
    }
});
jQuery('input[type=radio], input[type=checkbox], button[type="button"], a[href^="#"]').on('keydown', function(e) {
    jQuery('body').addClass('is-keyboard-pressed');
    keyboardNavigation = true;
});

function closeWrapTabs() {
    //console.log("closewarps");
    wrapTabsFirstChild = null;
    wrapTabsLastChild = null;
}

/**
 * 
 * @param container Contenedor de elementos para navegacion por Tabs
 */
function wrapTabs(container) {
    //console.log("-- wrapTabs");
    //console.log(container);
    closeWrapTabs();
    let childList = jQuery(container).find("select,input,a,button");
    //console.log(childList);
    wrapTabsLastChild = childList[childList.length - 1];
    wrapTabsFirstChild = null;
    wrapTabsFirstChild = childList[0];

    //console.log(wrapTabsFirstChild);
    //console.log(wrapTabsLastChild);
    if (jQuery(".is-keyboard-pressed").length) {
        jQuery(wrapTabsFirstChild)[0].focus();
    }

    jQuery(document).on("keydown", function(e) {
        if (e.key == "Tab") {
            /**
             * Consulta si tab está al final o al inicio de la lista de items
             */
            var target = jQuery(e.target);
            if (e.shiftKey) {
                /**
                 * Si shift está activo y se encuentra en el primer item =>
                 * focus al ultimo item
                 */
                if (target[0] == wrapTabsFirstChild) {
                    e.preventDefault();
                    jQuery(wrapTabsLastChild)[0].focus();
                    //console.log("got to last");
                }
            } else {
                //console.log(target);
                //console.log(wrapTabsLastChild);
                if (target[0] == wrapTabsLastChild) {
                    e.preventDefault();
                    jQuery(wrapTabsFirstChild)[0].focus();
                    //console.log("got to first");
                }
            }
        }
    });
}