// DOM
// this will help with the traslations
if ($('html').attr('lang') == 'en-US') {

    var messages = {
        zipcode: {
            valid: '"Please enter a valid zip code"',
            nsd: '"**This product is not available in this state, please select another product or service."',
            required: 'Please enter your zip code',
            health: '"State not supported"'
        },
        over18: {
            valid: 'The person\'s age must be 18 or over',
            required: 'Please enter an age over 18'
        },
        over19: {
            valid: 'The person\'s age must be 19 or over',
            required: 'Please enter an age over 19'
        },
        over16: {
            valid: 'The person\'s age must be 16 or over',
            required: 'Please enter an age over 16'
        },
        under100: {
            valid: 'Please enter a valid date'
        },
        min18: {
            required: 'Please enter an age over 18'
        },
        min19: {
            required: 'Please enter an age over 18'
        },
        date: {
            valid: 'Please enter a valid date'
        },
        address: {
            required: 'Please enter your address'
        },
        lastName: {
            required: 'Please enter your last name'
        },
        firstName: {
            required: 'Please enter your first name'
        },
        email: {
            required: 'Please enter your email'
        },
        phone: {
            required: 'Please enter your phone number',
            minlength: 'Please enter 10 digits',
            maxlength: 'Please enter 10 digits'
        },
        gender: {
            required: 'Please select your gender'
        },
        maritalStatus: {
            required: 'Please select your marital status'
        },
        city: {
            required: 'Please enter your city/state'
        },
        birth: {
            required: 'Please enter your date of birth'
        },
        licenseStatus: {
            required: 'Please enter your license status'
        },
        aptSuite: {
            required: 'Please enter your apt/suite'
        },
        yearExperience: {
            required: 'Please enter your years of driving experience'
        },
        numberIncidents: {
            required: 'Please select your number of incidents'
        },
        yearVehicle: {
            required: 'Please enter your vehicle year'
        },
        makeVehicle: {
            required: 'Please enter your vehicle make'
        },
        modelVehicle: {
            required: 'Please enter your vehicle model'
        },
        modificationVehicle: {
            required: 'Please enter your vehicle modification'
        },
        mileageVehicle: {
            required: 'Please enter your vehicle annual mileage'
        },
        useVehicle: {
            required: 'Please enter your vehicle use'
        },
        textarea: {
            required: 'Please enter your description of the issue'
        },
        petName: {
            required: 'Please enter your pet name'
        },
        petType: {
            required: 'Please select a pet type'
        }
    }

} else if ($('html').attr('lang') == 'es-US') {


    $.extend($.validator.messages, {
        required: "Este campo es obligatorio.",
        remote: "Por favor, rellena este campo.",
        email: "Por favor, escribe una dirección de correo válida.",
        url: "Por favor, escribe una URL válida.",
        date: "Por favor, escribe una fecha válida.",
        dateISO: "Por favor, escribe una fecha (ISO) válida.",
        number: "Por favor, escribe un número válido.",
        digits: "Por favor, escribe sólo dígitos.",
        creditcard: "Por favor, escribe un número de tarjeta válido.",
        equalTo: "Por favor, escribe el mismo valor de nuevo.",
        extension: "Por favor, escribe un valor con una extensión aceptada.",
        maxlength: $.validator.format("Por favor, no escribas más de {0} caracteres."),
        minlength: $.validator.format("Por favor, no escribas menos de {0} caracteres."),
        rangelength: $.validator.format("Por favor, escribe un valor entre {0} y {1} caracteres."),
        range: $.validator.format("Por favor, escribe un valor entre {0} y {1}."),
        max: $.validator.format("Por favor, escribe un valor menor o igual a {0}."),
        min: $.validator.format("Por favor, escribe un valor mayor o igual a {0}."),
        nifES: "Por favor, escribe un NIF válido.",
        nieES: "Por favor, escribe un NIE válido.",
        cifES: "Por favor, escribe un CIF válido."
    });

    var messages = {
        zipcode: {
            valid: '"Por favor, ingrese un código postal válido"',
            nsd: '"Este producto no está disponible en este estado, seleccione otro producto o servicio."',
            required: 'Por favor, ingrese su código postal'
        },
        over18: {
            valid: 'La edad de la persona debe ser mayor a 18 años',
            required: 'Por favor, ingrese una edad mayor a 18'
        },
        over19: {
            valid: 'The person\'s age must be 19 or over',
            required: 'Please enter an age over 19'
        },
        over16: {
            valid: 'La edad de la persona debe ser mayor a 16 años',
            required: 'Por favor, ingrese una edad mayor a 16'
        },
        under100: {
            valid: 'Por favor, introduzca una fecha valida'
        },
        min18: {
            required: 'Por favor, ingrese una edad mayor o igual a 18'
        },
        min19: {
            required: 'Por favor, ingrese una edad mayor a 18'
        },
        date: {
            valid: 'Por favor, introduzca una fecha valida'
        },
        address: {
            required: 'Por favor, ingrese tu dirección'
        },
        lastName: {
            required: 'Por favor, introduzca su apellido'
        },
        firstName: {
            required: 'Por favor, introduzca su nombre'
        },
        email: {
            required: 'Por favor, introduzca su correo electrónico'
        },
        phone: {
            required: 'Por favor, introduzca su número de teléfono',
            minlength: 'Por favor, ingrese 10 dígitos',
            maxlength: 'Por favor, ingrese 10 dígitos'
        },
        gender: {
            required: 'Por favor, seleccione su género'
        },
        maritalStatus: {
            required: 'Por favor, seleccione su estado civil'
        },
        city: {
            required: 'Por favor, introduzca su ciudad / estado'
        },
        birth: {
            required: 'Por favor, introduzca su fecha de nacimiento'
        },
        licenseStatus: {
            required: 'Por favor, ingrese el estado de su licencia'
        },
        aptSuite: {
            required: 'Por favor, ingrese su apartamento / suite'
        },
        yearExperience: {
            required: 'Por favor, ingrese sus años de experiencia conduciendo'
        },
        numberIncidents: {
            required: 'Por favor, seleccione su número de incidentes'
        },
        yearVehicle: {
            required: 'Por favor, ingrese el año de su vehículo'
        },
        makeVehicle: {
            required: 'Por favor, ingrese la marca de su vehículo'
        },
        modelVehicle: {
            required: 'Por favor, ingrese el modelo de su vehículo'
        },
        modificationVehicle: {
            required: 'Por favor, ingrese la modificación de su vehículo'
        },
        mileageVehicle: {
            required: 'Por favor, ingrese el kilometraje anual de su vehículo'
        },
        useVehicle: {
            required: 'Por favor, ingrese el uso de su vehículo'
        },
        textarea: {
            required: 'Por favor, ingrese su descripción del problema'
        },
        petName: {
            required: 'Por favor, ingrese el nombre de su mascota'
        },
        petType: {
            required: 'Por favor, selecione un tipo de mascota'
        }
    }
}

var MyApp = {

    onlyNumbers: function () {
        $('.only-numbers').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9]*$/.test(value);
            });
        });
    },
    onlyLetters: function () {
        $('.only-letters').each(function () {
            setInputFilter(this, function (value) {
                return /^[a-zA-ZáéíóúÁÉÍÓÚ\'´ñÑäëïöüÄËÏÖÜ \-]*$/.test(value);
            });
        });
    },
    onlyAlphaNumeric: function () {
        $('.only-alpha-numeric').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9a-zA-Z]*$/.test(value);
            });
        });
    },
    onlyPhone: function () {
        $('.only-phone').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9\-]*$/.test(value);
            });
        });
        $('.only-phone').mask('000-000-0000');
    },
    onlyPhoneUS: function () {
        $('.only-phone-us').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9() \-]*$/.test(value);
            });
        });
        $('.only-phone-us').mask('(000) 000-0000');
    },
    onlyDate: function () {
        $('.only-date').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9\/]*$/.test(value);
            });
        });
        $('.only-date').mask('99/99/0000');

    },
    form: function () {
        if ($('.c-hero-home-radio__form-items-input').length) {
            $('.c-hero-home-radio__form-items-input').on('focus', function () {
                $(this).addClass('has-focus').prev('.c-hero-home-radio__form-zipcode-label').addClass('is-floating');
            });

            $('.c-hero-home-radio__form-items-input').on('blur', function () {
                $(this).removeClass('has-focus');
                if ($(this).val() == '') {
                    $(this).prev('.c-hero-home-radio__form-zipcode-label').removeClass('is-floating')
                }
            });
        }
        $.validator.methods.email = function (value, element) {
                return this.optional(element) || /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
            },
            jQuery.validator.addMethod('date', function (value, element) {
                return this.optional(element) || /^((0?[1-9])|1[012])\/(([0-2][0-9])|([1-9])|(3[0-1]))\/(19|20)[0-9]{2}$/.test(value);
            }, messages.date.valid);
        $.validator.addMethod('over18', function (value, element) {
            let splitUserDOB = value.split('/');
            let DOB = new Date(splitUserDOB[2] + '/' + splitUserDOB[0] + '/' + splitUserDOB[1])
            let currentDate = new Date();
            if (value == '') {
                return true;
            } else {
                if (currentDate.getFullYear() - DOB.getFullYear() < 18) {
                    return false;
                } else if (currentDate.getFullYear() - DOB.getFullYear() == 18) {
                    if (currentDate.getMonth() < DOB.getMonth()) {
                        return false;
                    } else if (currentDate.getMonth() == DOB.getMonth()) {
                        if (currentDate.getDate() < DOB.getDate()) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }, messages.over18.valid);

        $.validator.addMethod('over16', function (value, element) {
            let splitUserDOB = value.split('/');
            let DOB = new Date(splitUserDOB[2] + '/' + splitUserDOB[0] + '/' + splitUserDOB[1])
            let currentDate = new Date();
            if (value == '') {
                return true;
            } else {
                if (currentDate.getFullYear() - DOB.getFullYear() < 16) {
                    return false;
                } else if (currentDate.getFullYear() - DOB.getFullYear() == 16) {
                    if (currentDate.getMonth() < DOB.getMonth()) {
                        return false;
                    } else if (currentDate.getMonth() == DOB.getMonth()) {
                        if (currentDate.getDate() < DOB.getDate()) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }, messages.over16.valid);

        $.validator.addMethod('under100', function (value, element) {
            let currentYear = new Date().getFullYear();
            if (value == '') {
                return true;
            } else {
                value = currentYear - (value.substr(value.length - 4));
                if (value <= 100) {
                    return true;
                } else {
                    return false;
                }
            }
        }, messages.under100.valid);

        var formList = []

        $('.form').each(function () {
            var form = $(this).validate({
                errorClass: 'form__error',
                validClass: 'form__valid',
                errorPlacement: function (error, element) {
                    if (element.hasClass('petType')) {
                        $('.c-quote-product__radio-pet-error').html(error);
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: function (form) {
                    $(form).find('button[type=submit]').prop('disabled', true)
                    $(form).addClass('form--loading');
                    if ($(form).hasClass('form--hippo')) {
                        $('.c-process-form').fadeOut();
                        $('.c-quote-product').fadeOut(function () {
                            $('.c-hippo-transition').fadeIn(function () {
                                $('.c-hippo-transition').attr('tabindex', -1).focus();
                            });
                        });
                    }
                    if ($(form).hasClass('ajax-quote-form-new')) {
                        //alert("ajax");
                        var type_insurance = $(form).find("input[type='radio'][name='type_insurance']:checked").val();
                        if (type_insurance == null) {
                            type_insurance = $(form).find('select[name="type_insurance"]').val();
                        }
                        var zipcode = $(form).find('input[name="zipcode"]').val();
                        if (type_insurance == null) {
                            type_insurance = $(form).find('input[name="type_insurance"]').val();
                        }
                        url_site = $('#url_site').val();
                        if (typeof url_site == 'undefined' || url_site == null) {
                            var url_site = '';
                        }
                        console.log(type_insurance);
                        console.log(zipcode);
                        if (type_insurance == 'A') {
                            //console.log("asd");
                            //url_site = "https://atalaya.confienextdev.com/generator/process";
                            $.ajax({
                                type: "POST",
                                //url: url_site+"/auto-insurance-quote/?zipCodeForm="+zipcode,
                                url: ATALAYA_URL,
                                dataType: 'JSON',
                                contentType: "application/json; charset=utf-8", // Añade la propiedad contentType
                                data: JSON.stringify({
                                    "language": LANGUAGE_SITE,
                                    "insurance": type_insurance,
                                    "device": "DE",
                                    "media": "WS",
                                    "brand": "FWY",
                                    "zip_code": zipcode
                                }),
                                success: function (data) {
                                    console.log(data);
                                    url_redirect = data.data.url_redirect;
                                    url = data.data.url;
                                    console.log(url_redirect);
                                    console.log(url);
                                    var form1 = document.createElement("form");
                                    var element1 = document.createElement("input");
                                    var element2 = document.createElement("input");
                                    var element3 = document.createElement("input");
                                    var element4 = document.createElement("input");
                                    form1.method = "POST";
                                    //form1.action = data.rater;
                                    form1.action = url_redirect;
                                    element1.value = data.data.zip_code;
                                    element1.name = "zipcode";
                                    form1.appendChild(element1);
                                    element2.value = data.data.media_code;
                                    element2.name = "media_code";
                                    form1.appendChild(element2);
                                    //element3.value = data.rater;
                                    element3.value = url_redirect;
                                    element3.name = "url";
                                    form1.appendChild(element3);
                                    element4.value = data.data.phone;
                                    element4.name = "contact_phone";
                                    form1.appendChild(element4);
                                    var element5 = document.createElement("input");
                                    //element5.value = data.lang;
                                    element5.value = LANGUAGE_SITE;
                                    element5.name = "lang";
                                    form1.appendChild(element5);
                                    
                                    document.body.appendChild(form1);
                                    form1.submit();
                                }
                            });
                        } else if (type_insurance == 'AMX' && $('html').attr('lang') == 'es-US') {
                            $.ajax({
                                type: "GET",
                                url: url_site+"/wp-content/themes/freewayv2/template-parts/quote-responsive.php?zipCodeForm="+zipcode,
                                dataType: 'JSON',
                                success: function (data) {
                                    console.log(1);
                                    var form1 = document.createElement("form");
                                    var element1 = document.createElement("input");
                                    var element2 = document.createElement("input");
                                    var element3 = document.createElement("input");
                                    var element4 = document.createElement("input");
                                    form1.method = "POST";
                                    form1.action = data.rater;
                                    element1.value = data.zip_code;
                                    element1.name = "zipcode";
                                    form1.appendChild(element1);
                                    element2.value = data.media_code;
                                    element2.name = "media_code";
                                    form1.appendChild(element2);
                                    element3.value = data.rater;
                                    element3.name = "url";
                                    form1.appendChild(element3);
                                    element4.value = data.phone;
                                    element4.name = "contact_phone";
                                    var element5 = document.createElement("input");
                                    element5.value = data.lang;
                                    element5.name = "lang";
                                    form1.appendChild(element5);
                                    form1.appendChild(element4);
                                    document.body.appendChild(form1);
                                    form1.submit();
                                }
                            });
                        } else if (type_insurance == 'AC') {
                            let url = "";
                            if ($('html').attr('lang') == 'en-US') {
                                url = "/classic-car-insurance-quote/?zipCodeForm="
                            } else {
                                url = "/cotizacion-de-seguro-de-auto-de-clasico/?zipCodeForm="
                            }
                            $.ajax({
                                type: "GET",
                                url: url_site + url + zipcode,
                                dataType: 'JSON',
                                success: function (data) {
                                    console.log(1);
                                    var form1 = document.createElement("form");
                                    var element1 = document.createElement("input");
                                    var element2 = document.createElement("input");
                                    var element3 = document.createElement("input");
                                    var element4 = document.createElement("input");
                                    form1.method = "POST";
                                    form1.action = data.rater;
                                    element1.value = data.zip_code;
                                    element1.name = "zipcode";
                                    form1.appendChild(element1);
                                    element2.value = data.media_code;
                                    element2.name = "media_code";
                                    form1.appendChild(element2);
                                    element3.value = data.rater;
                                    element3.name = "url";
                                    form1.appendChild(element3);
                                    element4.value = data.phone;
                                    element4.name = "contact_phone";
                                    var element5 = document.createElement("input");
                                    element5.value = data.lang;
                                    element5.name = "lang";
                                    form1.appendChild(element5);
                                    form1.appendChild(element4);
                                    document.body.appendChild(form1);
                                    form1.submit();
                                }
                            });
                        } else if (type_insurance == 'RSH') {
                            let url = "";
                            url = "/rideshare-insurance-quote/?zipCodeForm="
                            // if($('html').attr('lang') == 'en-US'){
                            //     
                            // }
                            $.ajax({
                                type: "GET",
                                url: url_site + url + zipcode,
                                dataType: 'JSON',
                                success: function (data) {
                                    console.log(1);
                                    var form1 = document.createElement("form");
                                    var element1 = document.createElement("input");
                                    var element2 = document.createElement("input");
                                    var element3 = document.createElement("input");
                                    var element4 = document.createElement("input");
                                    form1.method = "POST";
                                    form1.action = data.rater;
                                    element1.value = data.zip_code;
                                    element1.name = "zipcode";
                                    form1.appendChild(element1);
                                    element2.value = data.media_code;
                                    element2.name = "media_code";
                                    form1.appendChild(element2);
                                    element3.value = data.rater;
                                    element3.name = "url";
                                    form1.appendChild(element3);
                                    element4.value = data.phone;
                                    element4.name = "contact_phone";
                                    var element5 = document.createElement("input");
                                    element5.value = data.lang;
                                    element5.name = "lang";
                                    form1.appendChild(element5);
                                    form1.appendChild(element4);
                                    document.body.appendChild(form1);
                                    form1.submit();
                                }
                            });
                        } else {
                            console.log(2);
                            var form2 = document.createElement("form");
                            var element1 = document.createElement("input");
                            var element2 = document.createElement("input");
                            form2.method = "GET";
                            form2.action = url_site + "/submit-quote/";
                            element1.value = zipcode;
                            element1.name = "zipcode";
                            form2.appendChild(element1);
                            element2.value = type_insurance;
                            element2.name = "type_insurance";
                            form2.appendChild(element2);
                            document.body.appendChild(form2);
                            form2.submit();
                        }
                    } else {
                        //alert("no ajax");
                        form.submit();
                    }
                }
            });

            formList.push(form);
        });

        if ($('#zipcode').length) {
            $('#zipcode').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcode').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }

        if ($('#zipcodeHealth').length) {
            $('#zipcodeHealth').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: window.location.origin + '/api/insurance_quote_custom/valid.php?insurance_code=HE',
                    data: {
                        zip_code: function () {
                            return $('#zipcodeHealth').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        } else if (data === "2") {
                            return messages.zipcode.health;
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }


        if ($('#zipcodeProductQuote').length) {
            $('#zipcodeProductQuote').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcodeProductQuote').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if ($('#zipcodeQuote').length) {
            $('#zipcodeQuote').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcodeQuote').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if ($('#zipcodeOffice').length) {
            $('#zipcodeOffice').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcodeOffice').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if (jQuery("#zipcodeQuickQuote").length) {
            //validacion de elementos form de componente quick-quote 
            if (jQuery('#zipcodeQuickQuote').length) {
                var zipcodeQuickQuote = jQuery("#zipcodeQuickQuote");
                jQuery.each(zipcodeQuickQuote, function (index, el) {
                    jQuery(el).rules('add', {
                        required: true,
                        minlength: 3,
                        maxlength: 5,
                        remote: {
                            url: 'https://www.confiejarvis.com/thor/javascript?',
                            data: {
                                zipcode: function () {
                                    return jQuery(el).val();
                                }
                            },
                            dataFilter: function (data) {
                                if (data === "1") {
                                    return '"true"';
                                }
                                return messages.zipcode.valid;
                            }
                        },
                        messages: {
                            required: messages.zipcode.required
                        }
                    });
                });



            }
        }

        if ($('#zipcodeModalQuote').length) {
            $('#zipcodeModalQuote').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcodeModalQuote').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if ($('#zipcodeModalQuoteTurbo').length) {
            $('#zipcodeModalQuoteTurbo').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () {
                            return $('#zipcodeModalQuoteTurbo').val();
                        }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        }
                        return messages.zipcode.valid;
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if ($('#address').length) {
            $('#address').rules('add', {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.address.required
                }
            });
        }

        let urlSite = window.location.href;
        let urlValidation = 'https://buy.freewayinsurance.com';
        if (urlSite.includes('confiedev')) {
            urlValidation = 'https://nsd.confiedev.com';
        }

        // get all selects from quotes by class
        let quoteSelect = $('.c-quote__select');

        function selectRemote() {
            //console.log('funcion inciada');
            $this = $(this);
            // get product data-custom-zipcode-check of option selected
            let productSelected = $(this).find(':selected').data('custom-zipcode-check');
            // get product data-custom-slug of option selected
            let productType = $(this).find(':selected').data('custom-slug');
            // get adjacent zipcode input
            let zipcodeInput = $(this).closest('.c-quote').find('[id^="zipcode"]');
            // get container of zipcode input
            let zipcodeInputContainer = $(this).closest('.c-quote').find('[id^="zipcode"]').closest('.c-quote__item');
            // if productSelected is true
            if (productSelected && productType != 'HE') {
                //console.log("remote nsd");
                zipcodeInput.rules('remove');
                zipcodeInput.rules('add', {
                    required: true,
                    minlength: 3,
                    maxlength: 5,
                    remote: {
                        url: urlValidation + '/api/broker/product_cost/list/filter_by/product/' + productType + '?',
                        data: {
                            zipcode: function () {
                                return zipcodeInput.val();
                            }
                        },
                        dataFilter: function (data) {
                            zipcodeInputContainer.removeClass('is-nsd');
                            if (data === "3") {
                                return '"true"';
                            } else if (data === "2") {
                                zipcodeInputContainer.addClass('is-nsd');
                                return messages.zipcode.nsd;
                            }
                            return messages.zipcode.valid;
                        }
                    },
                    messages: {
                        required: messages.zipcode.required
                    }
                });

            } else if (productSelected && productType == 'HE') {
                //console.log("remote health");
                zipcodeInput.rules('remove');
                zipcodeInput.rules('add', {
                    required: true,
                    minlength: 3,
                    maxlength: 5,
                    remote: {
                        url: window.location.origin + '/api/insurance_quote_custom/valid.php?insurance_code=HE',
                        data: {
                            zip_code: function () {
                                return zipcodeInput.val();
                            }
                        },
                        dataFilter: function (data) {
                            if (data === "1") {
                                return '"true"';
                            } else if (data === "2") {
                                return messages.zipcode.health;
                            }
                            return messages.zipcode.valid;
                        }
                    },
                    messages: {
                        required: messages.zipcode.required
                    }
                });
            } else {
                //console.log("remote normal");
                zipcodeInput.rules('remove');
                zipcodeInput.rules('add', {
                    required: true,
                    minlength: 3,
                    maxlength: 5,
                    remote: {
                        url: 'https://www.confiejarvis.com/thor/javascript?',
                        data: {
                            zipcode: function () {
                                return zipcodeInput.val();
                            }
                        },
                        dataFilter: function (data) {
                            zipcodeInputContainer.removeClass('is-nsd');
                            if (data === "1") {
                                return '"true"';
                            }
                            return messages.zipcode.valid;
                        }
                    },
                    messages: {
                        required: messages.zipcode.required
                    }
                });
            }
            // get all zipcode inputs that are not empty
            if (!zipcodeInput.val() == '') {
                $(formList).each(function () {
                    let inputZipcode = $(this.currentForm).find('[id^="zipcode"]')
                    if (inputZipcode.val() != '') {
                        this.resetForm();
                        this.element($(this.currentForm).find('[id^="zipcode"]'));
                    }
                });
            } else {
                $(formList).each(function () {
                    this.resetForm();
                });
            }
            /*console.log('productSelected: '+ productSelected);
            console.log('productType: '+ productType);
            console.log('zipcodeInput: '+ zipcodeInput);
            console.log('zipcodeInputContainer: '+ zipcodeInputContainer);
            console.log();
            console.log();*/
        }
        quoteSelect.on('change', selectRemote);
        quoteSelect.trigger('change');

        let zipcodeInputs = $('[id^="zipcode"]');

        function isNsd() {
            //console.log('is-nsd remov');
            let $this = $(this);
            let inputWrapper = $this.closest('.c-quote__item');
            if (inputWrapper.hasClass('is-nsd') && $this.val() == '') {
                inputWrapper.removeClass('is-nsd');
            }
        }
        zipcodeInputs.on('keyup', isNsd);

        $('.lastName').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.lastName.required
                }
            });
        });
        $('.firstName').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.firstName.required
                }
            });
        });
        $('.address').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.address.required
                }
            });
        });
        $('.only-email').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 3,
                maxlength: 255,
                messages: {
                    required: messages.email.required
                }
            });
        });
        $('.only-phone').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 12,
                maxlength: 12,
                messages: {
                    required: messages.phone.required,
                    minlength: messages.phone.minlength,
                    maxlength: messages.phone.maxlength
                }
            });
        });
        $('.only-phone-us').each(function () {
            $(this).rules("add", {
                required: true,
                phoneUS: true,
                minlength: 14,
                maxlength: 14,
                messages: {
                    required: messages.phone.required,
                    minlength: messages.phone.minlength,
                    maxlength: messages.phone.maxlength
                }
            });
        });
        $('.gender').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.gender.required
                }
            });
        });
        $('.marital-status').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.maritalStatus.required
                }
            });
        });
        $('.city').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.city.required
                }
            });
        });

        $('.only-date').each(function () {
            $(this).rules("add", {
                date: true,
                //over18: true,
                under100: true,
                messages: {
                    required: messages.birth.required
                }
            });
        });


        $('.license-status').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.licenseStatus.required
                }
            });
        });
        $('.apt-suite').each(function () {
            $(this).rules("add", {
                minlength: 2,
                messages: {
                    required: messages.aptSuite.required
                }
            });
        });
        $('.year-experience').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.yearExperience.required
                }
            });
        });
        $('.number-incidents').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.numberIncidents.required
                }
            });
        });
        $('.year-vehicle').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.yearVehicle.required
                }
            });
        });
        $('.make').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                maxlength: 50,
                messages: {
                    required: messages.makeVehicle.required
                }
            });
        });
        $('.model').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                maxlength: 50,
                messages: {
                    required: messages.modelVehicle.required
                }
            });
        });
        $('.vehicle-modification').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                maxlength: 50,
                messages: {
                    required: messages.modificationVehicle.required
                }
            });
        });
        $('.annual-mileage').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.mileageVehicle.required
                }
            });
        });
        $('.vehicle-use').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.useVehicle.required
                }
            });
        });
        $('.textarea').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.textarea.required
                }
            });
        });
        $('.over18').each(function () {
            $(this).rules("add", {
                over18: true,
                messages: {
                    required: messages.over18.required
                }
            });
        });
        $('.over16').each(function () {
            $(this).rules("add", {
                over16: true,
                messages: {
                    required: messages.over16.required
                }
            });
        });
        $('.min18').each(function () {
            $(this).rules("add", {
                min: 18,
                messages: {
                    required: messages.min18.required
                }
            });
        });
        $('.min19').each(function () {
            $(this).rules("add", {
                min: 19,
                messages: {
                    required: messages.min19.required
                }
            });
        });
        $('.petName').each(function () {
            $(this).rules("add", {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.petName.required
                }
            });
        });
        $('.petType').each(function () {
            $(this).rules("add", {
                required: true,
                messages: {
                    required: messages.petType.required
                }
            });
        });
    },
}

$(function () {

    if ($('.only-numbers').length) {
        MyApp.onlyNumbers();
    }
    if ($('.only-date').length) {
        MyApp.onlyDate();
    }
    if ($('.only-letters').length) {
        MyApp.onlyLetters();
    }
    if ($('.only-alpha-numeric').length) {
        MyApp.onlyAlphaNumeric();
    }
    if ($('.only-phone').length) {
        MyApp.onlyPhone();
    }
    if ($('.only-phone-us').length) {
        MyApp.onlyPhoneUS();
    }
    if ($('.form').length) {
        MyApp.form();
    }
});

$(window).on('pageshow', function () {
    let submitButtonDisabled = $('button.is-disabled');
    let zipcodeHeroHomeInput = $('.c-hero-home-radio__form-items-input');
    console.log($('.c-hero-home-radio__form-items-input').val());
    if (zipcodeHeroHomeInput.val() !== '') {
        zipcodeHeroHomeInput.addClass('has-focus').prev('.c-hero-home-radio__form-zipcode-label').addClass('is-floating');
    }

    if (submitButtonDisabled.length) {
        submitButtonDisabled.removeClass('is-disabled')
    }
});
